import React, { useState, useEffect, useContext, createContext } from "react";
import axios from 'axios';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    // So within this auth is both the state and everything required to modify that state (sign in, sign out, recover password)
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
  
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(localStorage.getItem("fcDocManagement")!=null?JSON.parse(localStorage.getItem("fcDocManagement")):{});
  
  const cognitoAccessToken = async (token) => {
    const userObject = {token}
    localStorage.setItem("fcDocManagement", JSON.stringify(userObject))
    setUser(userObject)
    return userObject
  }

  const jwtsignin = async (username, password) => {
    const response = await axios.post('/api/users/login', {username, password})
    if (response.data.success){
      localStorage.setItem("fcDocManagement", JSON.stringify(response.data))
      setUser(response.data)
      return response.data
    }
    else{
      throw response.data
    }
  };

  const jwtsignup = async (username, password) => {
    const response = await axios.post('/api/users/register', {username, password})
    if (response.data.success){
      localStorage.setItem("fcDocManagement", JSON.stringify(response.data))
      setUser(response.data)
      return response.data
    }
    else{
      throw response.data
    }
  };

  const signout = async () => {
      // TODO When logging out, should also clear all related cache.
      // To that end, may be a good idea to prefix all related cache entries.
      // Perhaps prefex with the token.
      localStorage.removeItem("fcDocManagement")
      setUser({});
      return true
  };
  
  return {
    user,
    cognitoAccessToken,
    jwtsignin,
    jwtsignup,
    signout
  };
}