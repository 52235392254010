import './App.css';
import { useState, useEffect } from 'react'
import FCPlatform from './pages/FCPlatform';
import Organizer from './pages/Organizer';
import FCViewer from './pages/FCViewer'
import LoginScreen from './pages/Login'
import FCReview from './pages/FCReview'
import LandingPage from './pages/LandingPage'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { ProvideAuth } from "./hooks/use-auth";
import { ProvideSelectedDocs } from './hooks/use-selectedDocs'
import LoggedIn from './pages/LoggedIn';
import Pages from './Pages'

function App() {
  return (
    <ProvideAuth>
        <ProvideSelectedDocs>
          <Pages/>
        </ProvideSelectedDocs>
    </ProvideAuth>
  );
}

export default App;
