import React, {useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import Row from './Row'
import CollectionRow from './CollectionRow'
import {useAuth} from '../../hooks/use-auth'

export default function DocDisplay(props) {
    const {docData, setDocData, docsView, docsSelected, setDocsSelected} = props
    const {collections, setCollections} = props
    const auth = useAuth()

    const docRows = useMemo(()=>{
        const usedDocs = new Map()
        const idToDoc = new Map()
        docData.forEach(innerDoc=>{
            idToDoc.set(innerDoc.id, innerDoc)
        })
        const collectionRows = collections.map(collection=>{
            return (
                <>
                    <CollectionRow setCollections={setCollections} col={collection} docsSelected={docsSelected} setDocsSelected={setDocsSelected} />
                    {collection.documents.map(docID=>{
                        usedDocs.set(docID, true)
                        if (docsView.get(docID)){
                            const doc = idToDoc.get(docID)
                            if (doc){
                                return (
                                    <>
                                        <Row partOfCollection={true} docsSelected={docsSelected} setDocsSelected={setDocsSelected} setDocData={setDocData} key={doc.id} doc={doc}/>
                                    </>
                                )    
                            }
                        }
                    })}
                </>
            )
        })
        const remaining = docData.map(doc=>{
            if (!usedDocs.get(doc.id) && docsView.get(doc.id)){
                return (
                    <>
                        <Row docsSelected={docsSelected} setDocsSelected={setDocsSelected} setDocData={setDocData} key={doc.id} doc={doc}/>
                    </>
                )
            }
        }).filter(v=>v)

        const moveToRemaining = (e) => {
            e.stopPropagation()
            const selected = Array.from(docsSelected.entries()).filter(([k,v])=>v).map(([k,v])=>k)
            const selectedMap = new Map()
            selected.forEach(docID=>{
                selectedMap.set(docID, true)
            })
            setCollections(prev=>{
                console.log("old", prev)
                const newCollections = []
                prev.forEach(prevCollection=>{
                    const newDocuments = prevCollection.documents.filter(docID=>!selectedMap.has(docID))
                    const newCollection = {...prevCollection, documents: newDocuments}
                    newCollections.push(newCollection)
                })
                console.log("new", newCollections)
                axios.put(`/collections`, newCollections, {headers:{Authorization:auth.user.token}})
                return newCollections
            })
            // setDocsSelected(prev=>{
            //     const newDocsSelected = new Map(prev)  
            //     Array.from(newDocsSelected.keys()).map(k => {
            //         newDocsSelected.set(k, false)
            //     })
            //     return newDocsSelected
            // })
        }

        return (
            <>
            {collectionRows}
            <div onDragEnter={e=>e.preventDefault()} onDragOver={e=>e.preventDefault()} onDragLeave={e=>e.preventDefault()} onDrop={moveToRemaining} style={{minHeight: "200px"}}>
                {remaining}
            </div>
            </>
        )
    }, [docData, docsView, docsSelected, collections])
    
    return (
        <div class="container-fluid">
            <div class="row d-flex font-weight-bold my-3">
                <div class="col-2">Title</div>
                <div class="col-2">Last Accessed</div>
                <div class="col-6">Tags Contained</div>
            </div>
            {docRows}
        </div>
    )
}
