import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import Searchbar from '../components/OrganizerComponents/SearchBar'
import DocDisplay from '../components/OrganizerComponents/DocDisplay'
import NewDocButton from '../components/OrganizerComponents/NewDocButton'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {useAuth} from '../hooks/use-auth'
import {useSelectedDocs} from '../hooks/use-selectedDocs'
import './Organizer.css'
import LogoutButton from '../components/OrganizerComponents/LogoutButton'
import NewCollectionButton from '../components/OrganizerComponents/NewCollectionButton'

// main page for the application. The user can either choose to upload files
export default function Organizer() {
    const history = useHistory()
    const auth = useAuth();

    const [docData, setDocData] = useState([])
    const [collections, setCollections] = useState([])
    const [selectedDocs, sdController] = useSelectedDocs()
    const [docsView,setDocsView] = useState(new Map())
    const [docsSelected, setDocsSelected] = useState(new Map())
    
    useEffect(()=>{
        console.log("using user", auth)
        axios.get('/documents/meta',{headers:{Authorization: auth.user.token}})
        .then(data=>data.data).then(response=>response.Items)
        .then(docsList=>{
            console.log("docslist", docsList)
            const newDocsView = new Map()
            const newDocsSelected = new Map()
            docsList.forEach(doc=>{
                newDocsView.set(doc.id, true)
                newDocsSelected.set(doc.id, false)
            })
            setDocsView(newDocsView)
            setDocsSelected(newDocsSelected)
            setDocData(docsList)
        })
        axios.get('/collections',{headers:{Authorization: auth.user.token}})
        .then(data=>data.data).then(response=>response.Items)
        .then(collectionsList=>{
            console.log("colList", collectionsList)
            setCollections(collectionsList)
        })    
    }, [])

    const selectVisible = (e) => {
        const visibleList = docData.map(doc=>doc.id).filter(docID=>docsView[docID])
        console.log(visibleList)
        const newDocsSelected = {}
        visibleList.forEach(docID=>newDocsSelected[docID]=true)
        setDocsSelected({...docsSelected, ...newDocsSelected})
    }
    const unselectVisible = (e) => {
        const visibleList = docData.map(doc=>doc._id).filter(docID=>docsView[docID])
        console.log(visibleList)
        const newDocsSelected = {}
        visibleList.forEach(docID=>newDocsSelected[docID]=false)
        setDocsSelected({...docsSelected, ...newDocsSelected})
    }
    const unselectAll = (e) => {
        const newDocsSelected = {}
        docData.forEach(doc=>newDocsSelected[doc._id]=false)
        setDocsSelected(newDocsSelected)
    }    

    const openSelected = () =>{
        const idList = docData.filter(doc=>docsSelected.get(doc.id)).map(doc=>doc.id)
        const config = {
            headers:{Authorization: auth.user.token},
            params: {
                docs: idList.join(",")
            }
        }
        axios.get('/documents', config)
        .then(data=>{
            sdController.setDocs(data.data)
            history.push(`/editor/${idList.join(',')}`)
        })
    }
    const viewSelected = () =>{
        const idList = docData.filter(doc=>docsSelected.get(doc.id)).map(doc=>doc.id)
        const config = {
            headers:{Authorization: auth.user.token},
            params: {
                docs: idList.join(",")
            }
        }
        axios.get('/documents', config)
        .then(data=>{
            sdController.setDocs(data.data)
            history.push(`/fcviewer/${idList.join(',')}`)
        })
    }
    const reviewSelected = () =>{
        const idList = docData.filter(doc=>docsSelected.get(doc.id)).map(doc=>doc.id)
        const config = {
            headers:{Authorization: auth.user.token},
            params: {
                docs: idList.join(",")
            }
        }
        axios.get('/documents', config)
        .then(data=>{
            sdController.setDocs(data.data)
            history.push(`/fcreview/${idList.join(',')}`)
        })
    }

    return (
        <div className='container mt-5' style={{height: "100%", width: "100%"}}>
            <div className="row d-flex align-items-center my-2">
                <div className="mr-auto"><h1>Document Organizer</h1></div>
                <LogoutButton/>
            </div>
            <div className="row my-3">
            </div>
            <div className="row">
                <div className="col mr-auto">
                    <Searchbar docData={docData} docsView={docsView} setDocsView={setDocsView}/>
                </div>
                <div className="col-auto">
                    <button className="btn btn-secondary mr-1" onClick={openSelected}>Open Group</button>
                    <button className="btn btn-secondary mr-1" onClick={viewSelected}>View Group</button>
                    <button className="btn btn-secondary mr-1" onClick={reviewSelected}>Review Group</button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex my-2">
                    <NewDocButton sdController={sdController}/>
                    <NewCollectionButton setCollections={setCollections}/>
                </div>
            </div>
            <div className="row my-1">
                <DocDisplay collections={collections} setCollections={setCollections} docsSelected={docsSelected} setDocsSelected={setDocsSelected} docsView={docsView} docData={docData} setDocData={setDocData} />
            </div>
        </div>
    )
}
