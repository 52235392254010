import React, {useState, useEffect, useMemo, useRef} from 'react'
import {Container, Row} from 'react-bootstrap'
import MiniDisplay from '../components/MiniDisplay'
import {useFlashcards} from '../hooks/use-flashcards'
import {useLocation} from 'react-router-dom'
import Navbar from '../components/GeneralNavbar'

// Take in an array of flashcards, check their metadata, and allow users to review.
export default function FCReview() {
    const [forReview, setForReview] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [showFront, setShowFront] = useState(true)
    const location = useLocation()

    const [flashcards, fcController] = useFlashcards()

    function daysSince(recordedDate){
        return Math.ceil(((((Date.now() - recordedDate) / 1000) / 60) / 60) / 24)
    }
    function shouldReview(fc){
        const fcLevel = fc.level
        const lastReview = fc.lastReview
        
        const gap = daysSince(lastReview)
        return (2**fcLevel - 1) <= gap
    }

    useEffect(()=>{
        console.log("loc in review", location)
        const selected = location.state? location.state.selected: null

        const newFCMap = new Map()
        flashcards.forEach((fcList, i)=>{
            fcList.forEach((fc,j)=>{
                const isSelected = !selected || selected.get(`${i},${j}`)
                if (!("level" in fc && "lastReview" in fc && isSelected)){
                    const fcCopy = JSON.parse(JSON.stringify(fc))
                    fcCopy.level = 1
                    fcCopy.lastReview = Date.now()
                    newFCMap.set(`${i},${j}`, fcCopy)
                }
            })
        })
        console.log("lkadjf", newFCMap)
        if (newFCMap.size > 0){
            console.log("Correcting fcs", newFCMap)
            fcController.changeFlashcards(newFCMap)
            return
        }

        console.log("Setting up forReview")
        const newForReview = []
        flashcards.forEach((fcList, i)=>{
            fcList.forEach((fc,j)=>{
                if (shouldReview(fc)){
                    newForReview.push([i,j])
                }
            })
        })

        setForReview(newForReview)
    }, [flashcards])

    const goLeft = (e) => {
        e.preventDefault()
        currentIndex==0?setCurrentIndex(forReview.length-1):setCurrentIndex(currentIndex-1)
    }
    const goRight = (e) => {
        e.preventDefault()
        setCurrentIndex((currentIndex+1)%forReview.length)
    }
    const flip = (e) => {
        e.preventDefault()
        setShowFront(prev=>!prev)
    }
    const correct = (e) => {
        e.preventDefault()
        const [i,j] = forReview[currentIndex]
        const fc = flashcards[i][j]
        const newForReview = forReview.filter((fc,i)=>i!==currentIndex)

        setForReview(newForReview)
        fc.level += 1
        fc.lastReview = Date.now()

        fcController.changeFlashcard(i,j,fc)
    }
    const wrong = (e) => {
        setCurrentIndex((currentIndex+1)%forReview.length)
    }

    function FlashcardScreen(){
        if (flashcards.length === 0){
            return <h1>No flashcards Available</h1>
        }

        if (forReview.length === 0){
            return <h1>No cards to review!</h1>
        }

        console.log("fr", forReview)
        console.log("ci", currentIndex)
        const [i,j] = forReview[currentIndex]
        const fc = flashcards[i][j]
        const fcFront = fc.children[1]
        const fcBack = fc.children[2]

        return <MiniDisplay displayValue={[showFront?fcFront:fcBack]}/>
    }

    const saveFCs = () => {
        return fcController.saveFlashcards()
    }

    return (
        <>
            <Navbar saveWorking={saveFCs}/>
            <div className="d-flex flex-column align-items-center justify-content-center" style={{height:"100%"}}>
                <h1>{showFront?"Front":"Back"}</h1>

                <div style={{height: "50%", width: "50%"}}>
                    <FlashcardScreen/>
                </div>

                <div className="btn-group" role="group" aria-label="Basic example">
                    <button onClick={goLeft} type="button" className="m-1 btn btn-secondary">Left</button>
                    <button onClick={flip} type="button" className="m-1 btn btn-secondary">Flip</button>
                    <button onClick={correct} type="button" className="m-1 btn btn-secondary">Right</button>
                    <button onClick={wrong} type="button" className="m-1 btn btn-secondary">Wrong</button>
                    <button onClick={goRight} type="button" className="m-1 btn btn-secondary">Right</button>
                    <button onClick={saveFCs} type="button" className="m-1 btn btn-secondary">Save</button>
                </div>
            </div>  
        </>
    )
}
