// import { useSlate } from 'slate-react'
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap'
import {ChevronRight, ChevronLeft} from '@material-ui/icons';
import {Link, useParams, useHistory} from 'react-router-dom'

// TODO plugin options and other features to fit into the middle of the bar.

// Toolbar and associated operations
export default function Toolbar(props){
    const {saveWorking, selected} = props
    const {children} = props

    const params = useParams()
    const history = useHistory()
    const locationOrder = ["organizer","editor","fcviewer","fcreview"]
    const locationToName = {
        "organizer": "Organize",
        "editor": "Edit",
        "fcviewer": "View",
        "fcreview": "Review"
    }
    const currentLocation = history.location.pathname.split("/")[1]
    const splitAt = locationOrder.indexOf(currentLocation)
    const leftLinks = locationOrder.splice(0, splitAt+1)
    const rightLinks = locationOrder

    const Goto = (props) => {
        const {destination} = props
        const location = {
            pathname:`/${destination}/${params.id}`,
            state: selected
        }

        const clicked = () => {
            saveWorking()
            .then(()=>{
                history.push(location)
            })    
        }

        return (
            <div onClick={clicked}>
                {locationToName[destination]}
            </div>
        )
    }
    
    const style = {
    }

    const LinkGroup = (props) => {
        const {links} = props
        const linkList = []
        links.forEach(loc=>{
            linkList.push(<Goto destination={loc}/>)
            linkList.push(
                <div style={{border:"0.5px solid lightgray", margin: "1em", height:"60%"}}>
                </div>
            )
        })
        linkList.pop()
        return linkList
    }

    return (
        <Navbar style={style} bg="light" expand="lg">
            <div className="d-flex justify-content-between" style={{width: "100%"}}>
                <div className="d-flex align-items-center justify-content-center">
                    <ChevronLeft/><LinkGroup links={leftLinks}/>
                </div>
                {children}
                <div className="d-flex align-items-center justify-content-center">
                    {rightLinks.length > 0?<><LinkGroup links={rightLinks}/><ChevronRight/></>:<></>}
                </div>
            </div>
        </Navbar>
    )
}  
