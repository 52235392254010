import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom'
import {useAuth} from '../../hooks/use-auth'
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import {Modal} from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid';

export default function NewDocButton(props){
    const auth = useAuth()
    const history = useHistory()
    const {sdController} = props
    const [showModal, setShowModal] = useState(false)

    const createNew = (e) => {
        e.preventDefault()
    
        setShowModal(true)

        const newDocDetails = {
            id: uuidv4(),
            title: "Untitled",
            numberOfFlashcards: 0,
            contents: [{children:[{text:"This document was saved on creation. To delete, please delete from the organizer view."}]}],
        }
        axios.post('/documents', newDocDetails, {headers:{Authorization:auth.user.token}})
        .then(res=>{
            sdController.setDocs([newDocDetails])
            setShowModal(false)
            history.push(`/editor/${newDocDetails.id}`)
        })
    }

    return (
        <>
            <Modal show={showModal}>
                <Modal.Body>Creating New Document...</Modal.Body>
            </Modal>
            <button onClick={createNew} className="btn btn-outline-success d-flex align-items-center"><NoteAddIcon/>New Document</button>
        </>
    )
}