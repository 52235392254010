import React from 'react'
import MiniDisplay from '../MiniDisplay'

export default function FlashcardsView(props) {
    const {flashcards, fcController} = props
    const {selected, setSelected, visible} = props
    const {fcSize} = props

    console.log("fcfc", flashcards)
    console.log("vvv", visible)
    console.log(fcSize)
    const fcClassName = "" + " " + fcSize

    const SingleFlashcard = (fc, i, j) => {
        const coords = `${i},${j}`
        if (!visible.get(coords)){
            return
        }
        const style = {}
        if (selected.get(coords)){
            style.backgroundColor = "#E8F5FF"
        }
        // Below function is meant to deal with tag clicking.
        const changed = (newValue) => {
            fcController.changeFlashcard(i,j,newValue[0])
        }
        const clicked = () => {
            const newSet = new Map(selected)
            newSet.set(coords, !newSet.get(coords))
            setSelected(newSet)
        }
        return (
            <div key={j} className={fcClassName} onClick={clicked}>
                <div style={{width:"100%"}}>Sample something</div>
                <MiniDisplay forScreen="fcviewer" style={style} changed={changed} displayValue={[fc]}/>
            </div>
        )
    }

    return (
        <div className="col m-3">
            {flashcards.map((fcList,i)=>{
                const style={
                    backgroundColor:"white", 
                    border: "solid black 1px",
                    borderRadius: "16px",
                }
                return (
                    <div key={i} style={style} className="container p-3">
                        <div className="row">
                            {fcList.map((fc,j)=>SingleFlashcard(fc,i,j))}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}