import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom'
import {useAuth} from '../../hooks/use-auth'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { v4 as uuidv4 } from 'uuid';

export default function NewCollectoinButton(props){
    const auth = useAuth()
    const {setCollections} = props

    const createNew = (e) => {
        e.preventDefault()
    
        const newCollectionDetails = {
            collection: "New Collection",
            id: uuidv4(),
            documents: []
        }
        axios.post('/collections', [newCollectionDetails], {headers:{Authorization:auth.user.token}})
        setCollections(prev => [...prev, newCollectionDetails])
    }
    
    return (
        <button onClick={createNew} className="btn btn-outline-success d-flex align-items-center"><CreateNewFolderIcon/>New Collection</button>
    )
}