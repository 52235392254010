import React, {useState, useRef, useEffect} from 'react'
import { useAuth } from "./use-auth";
import { useHistory, useParams } from "react-router-dom"
import {useSelectedDocs} from './use-selectedDocs'

export function useFlashcards(){
    const [flashcards, setFlashcards] = useState([])
    const mapFCtoDoc = useRef(new Map())
    const [selectedDocs, sdController] = useSelectedDocs()

    const saveFlashcards = () => {
        console.log("savefc", flashcards)
        const sdCopy = JSON.parse(JSON.stringify(selectedDocs))
        flashcards.forEach((doc, i)=>{
            doc.forEach((fc,j)=>{
                const [loci, locj] = mapFCtoDoc.current.get(`${i},${j}`)
                console.log("copystuff", sdCopy[loci].contents[locj])
                console.log("fc", fc)
                sdCopy[loci].contents[locj] = fc
            })
        })
        console.log("completed saving", sdCopy)
        return sdController.saveDocs(sdCopy)
    }
    
    const changeFlashcard = (i,j,newfc) => {
        // const fcCopy = JSON.parse(JSON.stringify(flashcards))
        // fcCopy[i][j] = fc
        const newFC = flashcards.map((doc,x)=>{
            return doc.map((fc,y)=>{
                if (x === i && y === j){
                    return newfc
                }
                return fc
            })
        })
        setFlashcards(newFC)
    }

    // Given a map of coordinates to new flashcards
    // Will replace existing flashcards at that coordinate with new flashcard in map.
    const changeFlashcards = (newfcMap) => {
        console.log("newfcmap", newfcMap)
        const newFC = flashcards.map((doc,i)=>{
            return doc.map((fc,j)=>{
                const coords = `${i},${j}`
                if (newfcMap.has(coords)){
                    return newfcMap.get(coords)
                }
                return fc
            })
        })
        setFlashcards(newFC)
    }

    useEffect(()=>{
        if (flashcards.length === 0){
            let extractedFlashcards = []
            selectedDocs.forEach((doc,i)=>{
                const parsedContents = doc.contents
                let docFC = []
                parsedContents.forEach((node,j)=>{
                    if (node.type==="fcContainer"){
                        const FCi = extractedFlashcards.length
                        const FCj = docFC.length
                        mapFCtoDoc.current.set(`${FCi},${FCj}`, [i,j])
                        docFC.push(node)
                    }
                })
                extractedFlashcards.push(docFC)
            })    
            console.log("mapfc", mapFCtoDoc)
            console.log("efc", extractedFlashcards)
            setFlashcards(extractedFlashcards)
        }
    },[selectedDocs])

    return [flashcards, {
        changeFlashcard,
        changeFlashcards,
        saveFlashcards
    }]
}