import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import {renderElement} from "../EditorHelpers/elementRender"
import {renderLeaf} from "../EditorHelpers/leafRender"
import { withFlashcards } from "../EditorHelpers/flashcard"
import { createEditor, Element as SlateElement } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import Sidebar from "./Sidebar"
import {useSelectedDocs} from '../hooks/use-selectedDocs'

export default function FCEditor(props) {
    const {initDoc} = props
    const {showOnlyFlashcards, updateDoc} = props
    // const {showSidebar} = props
    const [selectedDocs, sdController] = useSelectedDocs()
    const {saveEverything} = props

    const [showSidebar, setShowSidebar] = useState(Date.now())

    const [value, setValue] = useState(initDoc.contents)
    const [title, setTitle] = useState(initDoc.title)
    const editor = useMemo(
        () => withFlashcards(withHistory(withReact(createEditor()))),
        [])

    const leafRender = useCallback(renderLeaf, [])

    const elementRender = useCallback(props=>{
        if (showOnlyFlashcards){
            return
        }
        return renderElement(props)}
    , [showOnlyFlashcards])

    const lastUpdated = useRef(Date.now())
    const buildDocMeta = () => {
        const tagList = new Map()
        value.forEach(node=>{
          if (node.type === 'fcContainer'){
            node.children[0].children.forEach(tag=>{
              if (tag.type === "fcTag"){
                tagList.set(tag.children[0].text, true)
              }
            })
          }
        })
        console.log("got tags", Array.from(tagList.keys()))
        const newDoc = {
            ...initDoc,
            title: title,
            contents: value,
            tagList: Array.from(tagList.keys())
        }
        return newDoc
    }
    const saveDoc = () => {
        const docMeta = buildDocMeta()
        console.log(docMeta)
        sdController.saveDocsWithoutSetting([docMeta])
    }
    useEffect(() => {
        // if (Date.now() - lastUpdated.current > 5000){
        //     console.log("updating")
        //     lastUpdated.current = Date.now()
        //     saveDoc()
        // }
        updateDoc({title, contents: value})
    }, [value, title])

    const style = {
        backgroundColor:"white",
        borderRadius: "0.2em",
    }

    const clicked = (e) =>{
        setShowSidebar(Date.now()%100000000)
    }

    const sidebarStyle = {
        zIndex: showSidebar
    }

    return (
        <Slate
          editor={editor}
          value={value}
          onChange={newValue => setValue(newValue)}>
                {/* {showSidebar?<Sidebar/>:<></>} */}
                <Sidebar style={sidebarStyle}/>
                <div onClick={clicked} style={style} className="p-4 col-md-9 col-lg-8 col-xl-7 d-flex flex-column writingSpace shadow-sm">
                    <div className="titleBox"><input className="title w-100" value={title} onChange={e=>setTitle(e.target.value)}/></div>
                    <hr/>
                    <Editable
                    className="mainEditor"
                    renderElement={elementRender}
                    renderLeaf={leafRender}/>
                </div>
        </Slate>
    )
}
