import React, {useState, useContext, createContext, useEffect, useRef} from 'react'
import { useAuth } from "./use-auth";
import { useHistory, useParams } from "react-router-dom"
import axios from 'axios'
import _ from 'lodash'

const sdContext = createContext();

// TODO: Make some simplifying assumptions.

export const useSelectedDocs = () => {
    const [selectedDocs, sdController] = useContext(sdContext)
    const auth = useAuth()
    const params = useParams()
    sdController.setAuthAndParams(auth, params)

    useEffect(()=>{
        if (params.id && selectedDocs.length === 0 && auth){
            console.log("refreshing sd")
            const savedDocs = JSON.parse(localStorage.getItem("storedSelectedDocs"))
            console.log("from ls", savedDocs)
            const savedIDList = savedDocs.map(doc=>doc.id)
            const paramsIDList = params.id.split(",")
            console.log("saved and params", savedIDList, paramsIDList)
            const matching = _.intersection(savedIDList, paramsIDList)
            if (matching.length != paramsIDList.length){
                console.log("not matching with params")
                // GET IT FROM THE API
            }
            else{
                sdController.setDocs(savedDocs)
            }
        }
    }, [])

    return [selectedDocs, sdController];
};

export function ProvideSelectedDocs({ children }) {
    const sd = useProvideSelectedDocs();
    return <sdContext.Provider value={sd}>{children}</sdContext.Provider>;
}

export function useProvideSelectedDocs(){
    const [selectedDocs, setSelectedDocs] = useState([])
    const currentAuth = useRef({})
    const currentParams = useRef({})

    function setAuthAndParams(auth, params){
        currentAuth.current = auth
        currentParams.current = params
    }

    // Should be called by organizer
    async function getFromBackend(docIDList){

    }

    function setDocs(newSelectedDocs){
        localStorage.setItem("storedSelectedDocs", JSON.stringify(newSelectedDocs))
        setSelectedDocs(newSelectedDocs)
    }

    function setDocsFromMap(docMap){
        setSelectedDocs(prev=>{
            console.log(prev)
            const newSelectedDocs = prev.map(oldDoc=>{
                const id = oldDoc.id
                return {...oldDoc, title: docMap.get(id).title, value: docMap.get(id).value}
            })
            console.log(newSelectedDocs)
            localStorage.setItem("storedSelectedDocs", JSON.stringify(newSelectedDocs))
        })
    }

    function changeDoc(docID, newDoc){
        const newSD = selectedDocs.map(doc=>{
            if (doc._id === docID){
                return newDoc
            }
            return doc
        })
        setSelectedDocs(newSD)
    }

    function saveDocs(newDocs){
        if (newDocs){
            console.log("saving given documents", newDocs)
            setDocs(newDocs)
            const userToken = currentAuth.current.user.token
            return axios.put('/documents', newDocs, {headers:{Authorization:userToken}})
            .then(data=>console.log("done", data))
        }
        else{
            console.log("saving stored documents")
            const userToken = currentAuth.user.token
            return axios.put('/documents', selectedDocs, {headers:{Authorization:userToken}})
            .then(data=>console.log("done", data))
        }
    }

    function saveDocsWithoutSetting(newDocs){
        console.log("saving given documents", newDocs)
        const userToken = currentAuth.current.user.token
        return axios.put('/documents', newDocs, {headers:{Authorization:userToken}})
        .then(data=>console.log("done", data))
    }

    return [selectedDocs, {setAuthAndParams, setDocs, setDocsFromMap, changeDoc, saveDocs, saveDocsWithoutSetting}]
}
