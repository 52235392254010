import FCPlatform from './pages/FCPlatform';
import Organizer from './pages/Organizer';
import FCViewer from './pages/FCViewer'
import FCReview from './pages/FCReview'
import LandingPage from './pages/LandingPage'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import LoggedIn from './pages/LoggedIn';
import ProtectedRoute from './components/ProtectedRoute'

export default function Pages() {
  return (
      <Router>
          <Switch>
            <Route exact path="/">
              <LandingPage/>
            </Route>
            <Route path="/loggedin">
              <LoggedIn/>
            </Route>
            <ProtectedRoute>
                <Route path="/organizer">
                <Organizer/>
                </Route>
                <Route path="/editor/:id">
                <FCPlatform/>
                </Route>
                <Route path="/fcviewer/:id">
                <FCViewer/>
                </Route>
                <Route path="/fcreview/:id">
                <FCReview/>
                </Route>
            </ProtectedRoute>
          </Switch>
      </Router>
  );
}
