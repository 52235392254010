import {flashcardRender, isFlashcard} from './flashcard'

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
      case 'block-quote':
        return <blockquote className="blockquote" {...attributes}>{children}</blockquote>
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>
      case 'list-item':
        return <li {...attributes}>{children}</li>
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
      default:
        return <p style={{margin:"5px"}} {...attributes}>{children}</p>
    }
  }

export const renderElement = (props) => {
    if (isFlashcard(props.element)){
        return flashcardRender(props)
    }
    else{
        return <Element {...props} />
    }
}