import {useState} from 'react'
// Must plug this into the backend
import {useHistory} from 'react-router-dom'
import {useAuth} from '../hooks/use-auth'
import {Tabs, Tab, Container, Modal} from 'react-bootstrap'

export default function LandingPage({}) {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const auth = useAuth()
    const history = useHistory();

    const goingToOrganizer = {
        pathname: '/organizer',
        state: { fromLogin: true }
    }

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{height: "100%", width:"100%"}}>
            {"token" in auth.user?
                history.push(goingToOrganizer)
                :<div>
                    <h1>Welcome to Flashcard Organizer</h1>
                    <div>
                        <a href="https://somethingrandomisdone.auth.us-east-2.amazoncognito.com/login?client_id=6c4d2mn8devis1emvp5ikghrtq&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http://localhost:3000/loggedin">
                            Click me to go to the login screen
                        </a>
                    </div>
                </div>}
        </Container>
    )
}
