import {useState} from 'react'

export default function SearchBar({docData, setDocsView}) {
    const [searchVal, setSearchVal] = useState("")

    const titleSearch = (e) => {
        e.preventDefault()
        const workingVal = e.target.value
        setSearchVal(workingVal)

        const searchWords = workingVal.split(" ").map(word=>word.toLowerCase())
        let visibleList  = docData
        searchWords.forEach(word=>{
            visibleList = visibleList.filter(doc=>doc.title.toLowerCase().indexOf(word) > -1)
        })

        setDocsView(prev=>{
            console.log("plist", prev)
            const newDocsView = new Map()
            Array.from(prev.entries()).forEach(([key,value])=>{
                newDocsView.set(key, false)
            })
            visibleList.forEach(doc=>newDocsView.set(doc._id,true))
            console.log("vlist", visibleList)
            return newDocsView
        })
    }

    return (
        <input type="text" style={{width: "100%"}} className="form-control" id="metaSearch" name="metaSearch" value={searchVal} onChange={titleSearch} placeholder="Search Titles"/>
    )
}
