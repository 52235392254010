import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import { useSelectedDocs } from '../../hooks/use-selectedDocs'
import axios from 'axios'
import {useAuth} from '../../hooks/use-auth'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import {Dropdown, Modal} from 'react-bootstrap'

export default function Row(props){
    const [selectedDocs, sdController] = useSelectedDocs()
    const {docsSelected, setDocsSelected} = props
    const {partOfCollection} = props
    const {setDocData} = props
    const {doc} = props
    const [renameValue, setRenameValue] = useState(null)

    const history = useHistory()
    const auth = useAuth()

    const selectSingle = (e) => {
        setDocsSelected(prev=>{
            const newDocsSelected = new Map(prev)
            newDocsSelected.set(doc.id, !prev.get(doc.id))
            return newDocsSelected
        })
    }

    let style = {borderBottom: "solid lightgray 1px", backgroundColor: "white"}
    if (docsSelected.get(doc.id)){
        style.backgroundColor = "#d4e4ff"
    }
    if (partOfCollection){
        style.paddingLeft = "1.5em"
    }

    const deleteDoc = (e) => {
        console.log(`deleting ${doc.id}`)
    }
    const [showModal, setShowModal] = useState(false)
    const openSingle = (e) =>{
        setShowModal(true)
        const config = {
            headers:{Authorization: auth.user.token},
            params: {
                docs: doc.id
            }
        }
        axios.get('/documents', config)
        .then(data=>{
            setShowModal(false)
            sdController.setDocs(data.data)
            history.push(`/editor/${doc.id}`)    
        })
        .catch(err=>{
            console.log(err)
        })
    }
    const submitRename = (e) => {
        if (e.keyCode == 13){
            const newDoc = {...doc, title: renameValue}
            axios.put('/documents/meta', [newDoc], {headers:{Authorization:auth.user.token}})
            setDocData(prev=>{
                const newDocData = prev.map(docDetails => {
                    if (docDetails.id == doc.id){
                        return {...doc, title: renameValue}
                    }
                    return docDetails
                })
                console.log("newDocData", newDocData)
                return newDocData
            })
            setRenameValue(null)
        }
        else if (e.keyCode === 27){
            setRenameValue(null)   
        }
    }
    const changeToRename = (e) =>{
        setRenameValue(doc.title)
        e.stopPropagation()
    }

    const dragged = (e) => {
        setDocsSelected(prev=>{
            const newDocsSelected = new Map(prev)
            newDocsSelected.set(doc.id, true)
            return newDocsSelected
        })
        e.target.classList.add("selectedRow")
        let container = document.createElement("div")
        container.style.position = "absolute"; 
        container.style.top = "0px"; 
        container.style.left = "-9000px";
        container.style.width = "80%"
        container.id = "draggedImage"
        Array.from(document.getElementsByClassName("selectedRow")).forEach(row=>{
            var crt = row.cloneNode(true);
            crt.classList.remove("selectedRow")
            container.appendChild(crt)
        })
        document.body.appendChild(container);
        e.dataTransfer.setDragImage(container, 0, 0);    
    }
    const letGo = (e) => {
        document.getElementById("draggedImage").remove()
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation()
            onClick(e);
        }}
        onMouseOver={e=>e.target.style.backgroundColor="lightgray"} 
        onMouseLeave={e=>e.target.style.backgroundColor="white"}
        >
        {children}
        <MoreVertIcon style={{borderRadius:"100%", height: "1.5em", width:"1.5em", padding:"0.3em"}} />
        </a>
    ));

    // Hover Effects
    const addHighlight = (e) => {
        e.target.style.backgroundColor = "red"
        e.stopPropagation()
    }
    const removeHighlight = (e) => {
        e.target.style.backgroundColor = docsSelected.get(doc.id)? "lightblue":"white"
        e.stopPropagation()
    }

    // const titleInput = <input type="text" value={renameValue} onChange={e=>setRenameValue(e.target.value)} onKeyDown={submitRename}/>
    const titleInput = <input onClick={e=>e.stopPropagation()} type="text" value={renameValue} onChange={e=>setRenameValue(e.target.value)} onKeyDown={submitRename}/>
    const titleShow = <div onClick={changeToRename}>{doc.title}</div>
    const tagList = doc.tagList.map(tag=><div className="mx-1 px-2" style={{backgroundColor:"lightblue", borderRadius:"10px"}} key={tag}>{tag}</div>)
    let className = "row d-flex"
    if (docsSelected && docsSelected.get(doc.id)){
        className = "row d-flex selectedRow"
    }
    return (
    <>
        <Modal show={showModal}>
            <Modal.Body>Loading Document...</Modal.Body>
        </Modal>
        <div 
        onClick={selectSingle} 
        draggable={true} 
        onDragStart={dragged} 
        onDragEnd={letGo} 
        style={style} 
        key={doc.id} 
        className={className}
        >
            <div className="col-2 d-flex align-items-center overflow-auto">
                <InsertDriveFileIcon style={{color:"lightblue"}}/>
                {renameValue === null?titleShow:titleInput}
            </div>
            <div className="col-2 d-flex align-items-center">{
            new Date(doc.lastAccessed).toLocaleDateString(
                'en-gb',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                }
              )}</div>
            <div className="col-6 d-flex align-items-center">{tagList}</div>
            <div className="ml-auto d-flex align-items-center justify-content-center">
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={openSingle}>Open</Dropdown.Item>
                        <Dropdown.Item onClick={changeToRename}>Rename</Dropdown.Item>
                        <Dropdown.Item onClick={deleteDoc}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    </>
    )
}