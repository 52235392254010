import {useState, useEffect} from 'react'
// Must plug this into the backend
import {useHistory, useParams, Redirect, useLocation} from 'react-router-dom'
import {useAuth} from '../hooks/use-auth'
import {Container} from 'react-bootstrap'

export default function LoggedIn({}) {
    const auth = useAuth()
    const history = useHistory();
    const location = useLocation()

    const goingToOrganizer = {
        pathname: '/organizer',
        state: { fromLogin: true }
    }

    useEffect(()=>{
        const accessToken = location.hash.split("&")[1].split("=")[1]
        auth.cognitoAccessToken(accessToken)
        .then(()=>{
            history.push(goingToOrganizer)
        })
    }, [])

    return (
        <>
        </>
    )
}
