import React, {useState, useEffect} from 'react'

export default function FCViewSidebar(props){
    const {flashcards, fcController, selected, setVisible} = props
    
    const [tagPool, setTagPool] = useState([])
    const [searchTags, setSearchTags] = useState([])
    const [searchVal, setSearchVal] = useState("")
    const [inputTag, setInputTag] = useState("")

    useEffect(() => {
        let tagSet = new Set()
        for (let doc of flashcards){
            for (let fc of doc){
                const tagList = fc.children[0].children.filter(n=>n.type=="fcTag").map(n=>n.children[0].text)
                tagList.forEach(tag=>tagSet.add(tag))
            }
        }
        setTagPool([...tagSet])
    }, [flashcards])

    useEffect(() => {
        console.log("searchTags", searchTags)
        if (searchTags.length === 0){
            const newView = new Map()
            flashcards.forEach((doc,i)=>{
                doc.forEach((fc,j)=>{
                    newView.set(`${i},${j}`, true)
                })
            })
            setVisible(newView)
            return
        }

        let newView = new Map()
        flashcards.forEach((doc, i)=>{
            doc.forEach((fc,j)=>{
                const tagList = fc.children[0].children.filter(n=>n.type=="fcTag").map(n=>n.children[0].text)
                let contains = true
                searchTags.forEach(searchTag=>{
                    if (!tagList.includes(searchTag)){
                        contains = false
                    }
                })
                if (contains){
                    newView.set(`${i},${j}`, true)
                }
                else{
                    newView.set(`${i},${j}`, false)
                }
            })
        })
        setVisible(newView)
    }, [searchTags])

    const workWithTag = (tag) => (e) => {
        console.log(tag)
        if (selected.size === 0){
            // Nothing has been selected, so we just adjust the search tags.
            if (searchTags.includes(tag)){
                setSearchTags(searchTags.filter(t=>t!==tag))
            }
            else{
                setSearchTags([...searchTags, tag])
            }
        }
        else{
            const newfcMap = new Map()
            flashcards.forEach((doc, i)=>{
                doc.forEach((fc,j)=>{
                    const coords = `${i},${j}`
                    if (selected.get(coords)){
                        const fcCopy = JSON.parse(JSON.stringify(fc))
                        const newTag = {
                            type: "fcTag",
                            children: [{text: tag}]
                        }
                        const TagList = fcCopy.children[0].children
                        // TODO Toggle the tag properly.
                        const TagListText = TagList.map(subtag=>subtag.children[0].text)
                        console.log("tlt",TagListText)
                        if (TagListText.includes(tag)){
                            const withoutTag = TagList.filter(subtag=>subtag.children[0].text !== tag)
                            fcCopy.children[0].children = withoutTag
                        }
                        else{
                            TagList.unshift(newTag)
                        }
                        console.log("fcfcCopy", fcCopy)
                        newfcMap.set(coords, fcCopy)
                    }
                })
            })
            fcController.changeFlashcards(newfcMap)
        }
    }
    const Tag = ({tag}) =>{
        let style = {
            backgroundColor: "#E3FFFC",
            borderRadius: "5px",
        }
        if (tag in tagPool){
            style = {...style, backgroundColor: "#6791FF"}
        }

        return (
            <div key={tag} className="m-1 p-2" onClick={workWithTag(tag)} style={style}>
                {tag}
            </div>
        )
    }

    const style = {
        height: "100vh",
        backgroundColor:"white",
    }

    return (
        <>
            <div style={style} className="d-flex p-2 flex-column align-items-center">
                <h3 className="m-2 p-2">Tag Pool</h3>
                <div>Select a tag to search</div>
                <div className="m-2 p-2">Click on a tag after selecting flashcards to toggle the tag on selected flashcards.</div>
                <div className="d-flex flex-wrap">
                    {tagPool.map(tag=><Tag tag={tag}/>)}
                </div>
                {/* <input type="text" className="form-control" id="metaSearch" name="metaSearch" value={searchVal} onChange={e=>setSearchVal(e.target.value)} placeholder="Search Content"/> */}
            </div>
        </>
    )
}