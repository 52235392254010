// Definitely needed for the sake of mass management of flashcards.
import React, {useEffect, useState, useCallback, useRef} from 'react'
import Navbar from "../components/GeneralNavbar"
import {useDocStorage} from '../hooks/loadDocs'
import Sidebar from '../components/FCViewSidebar'
import FlashcardsView from '../components/FCViewer/FlashcardsView'
import {useFlashcards} from '../hooks/use-flashcards'
import {useSelectedDocs} from '../hooks/use-selectedDocs'
import SelectionBar from '../components/FCViewer/SelectionBar'
import "./FCViewer.css"
import {Dropdown} from 'react-bootstrap'

// PROPS: docArray - array of slatejs json documents.
export default function FCViewer() {
    const [selected, setSelected] = useState(new Map())
    const [visible, setVisible] = useState(new Map())
    const [flashcards, fcController] = useFlashcards()
    const [fcSize, setFcSize] = useState("col-6")

    useEffect(()=>{
        const newView = new Map()
        flashcards.forEach((doc,i)=>doc.forEach((n,j)=>{
            newView.set(`${i},${j}`,true)
        }))
        setVisible(newView)
        const newSelected = new Map()
        flashcards.forEach((doc,i)=>doc.forEach((n,j)=>{
            newSelected.set(`${i},${j}`, false)
        }))
        setSelected(newSelected)
    }, [flashcards])

    function saveFlashcards(){
        return fcController.saveFlashcards()
    }

    return (
        <>
            <Navbar saveWorking={saveFlashcards}>
                <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                        Flashcard Size
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={()=>setFcSize("col-12")}>Full Size</Dropdown.Item>
                        <Dropdown.Item onClick={()=>setFcSize("col-6")}>Half Size</Dropdown.Item>
                        <Dropdown.Item onClick={()=>setFcSize("col-4")}>Third Size</Dropdown.Item>
                        <Dropdown.Item onClick={()=>setFcSize("col-3")}>Quarter Size</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Navbar>
            <div className="" style={{minHeight: "100vh", backgroundColor:"lightgray"}}>
                <div className="row no-gutters">
                    <div style={{backgroundColor:"black"}} className="col-3">
                        <Sidebar flashcards={flashcards} fcController={fcController} selected={selected} setVisible={setVisible}/>
                    </div>
                    <div className="col-9">
                        <SelectionBar setSelected={setSelected} visible={visible}/>
                        <div className="row">
                            <FlashcardsView flashcards={flashcards} fcController={fcController} selected={selected} setSelected={setSelected} visible={visible} fcSize={fcSize}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
