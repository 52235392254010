import React from 'react'
import { Editor, Transforms, Text, Element as SlateElement } from 'slate'
import { createFC } from '../EditorHelpers/flashcard'
import { Button } from '@material-ui/core'
import Icon from '@material-ui/core/Icon';
import {useEditor} from 'slate-react'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

// TODO images, tables, equations.
// images are a given, tables have existing plugins, and equations should have 

const toggleFormat = (editor, format) => {
    const isActive = isFormatActive(editor, format)
    Transforms.setNodes(
      editor,
      { [format]: isActive ? null : true },
      { match: Text.isText, split: true }
    )
  }
  
const isFormatActive = (editor, format) => {
const [match] = Editor.nodes(editor, {
    match: n => n[format] === true,
    mode: 'all',
})
return !!match
}
const FormatButton = ({ format, icon, editor }) => {
  const bStyle = {
    height: "50px",
    width: "50px",
    border: "solid 1px black",
  }
  return (
    <button
      reversed
      active={isFormatActive(editor, format)?true:undefined}
      onMouseDown={event => {
        event.preventDefault()
        toggleFormat(editor, format)
      }}
      style={bStyle}
    >
      {icon}
    </button>
  )
}

let addFC = (editor) => {
  const cloneFC = [createFC(),{type:"paragraph", children:[{text:""}]}]
  // TODO: Cannot add flashcard if already inside a flashcard.
  // Always add flashcard to next non-nested spot from selection, or the selection itself if it qualifies.
  if (editor.selection){
    const selectionPath = editor.selection.anchor.path
    console.log("current cursor at", selectionPath)
    let insertAt = [selectionPath[0]]
    const [node, nodeAt] = Editor.node(editor, insertAt)
    console.log(node)
    if (node.type === "paragraph"){
      insertAt = [insertAt[0]+1]
    }
    if (node.type === "fcContainer"){
      insertAt = [insertAt[0]+2]
    }
    Transforms.insertNodes(
      editor, 
      cloneFC,
      {at: insertAt}
    )
  }
  // TODO: Check if there are any headers above, grab it and place it into the flashcard.
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  })
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)
  console.log("before",editor)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
    console.log("after",editor)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const BlockButton = ({ format, icon, editor }) => {
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon, editor }) => {
  return (
    <Button
      active={isMarkActive(editor, format)?true:undefined}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}
const SideBar = (props) => { 
    const editor = useEditor()

    return (
      <div style={props.style} className="sidebar d-flex flex-column">
          <MarkButton editor={editor} format="bold" icon="format_bold" />
          <MarkButton editor={editor} format="italic" icon="format_italic" />
          <MarkButton editor={editor} format="underline" icon="format_underlined" />
          <MarkButton editor={editor} format="code" icon="code" />
          <BlockButton editor={editor} format="heading-one" icon="looks_one" />
          <BlockButton editor={editor} format="heading-two" icon="looks_two" />
          <BlockButton editor={editor} format="block-quote" icon="format_quote" />
          <BlockButton editor={editor} format="numbered-list" icon="format_list_numbered" />
          <BlockButton editor={editor} format="bulleted-list" icon="format_list_bulleted" />
          <Button onMouseDown={event=>addFC(editor)}><Icon>add_box</Icon></Button>
      </div>
    )
  }
  
export default SideBar