import React from 'react'

export default function SelectionBar(props) {
    const {setSelected, visible} = props

    const selectVisible = (e) => {
        setSelected(prev=>{
            const newSelection = new Map(prev)
            visible.forEach((v,k)=>{
                newSelection.set(k,true)
            })
            return newSelection
        })
    }
    const unselectVisible = (e) => {
        setSelected(prev=>{
            const newSelection = new Map(prev)
            visible.forEach((v,k)=>{
                newSelection.set(k,false)
            })
            return newSelection
        })
    }

    const style={
        backgroundColor: "white",
        height: "50px",
        width: "100%"
    }

    return (
        <div className="shadow-sm d-flex align-items-center" style={style}>
            <button style={{height: "80%"}} onClick={selectVisible} className="btn btn-light mx-1">Select Visible</button>
            <button style={{height: "80%"}} onClick={unselectVisible} className="btn btn-light mx-1">Unselect Visible</button>
        </div>
    )
}
