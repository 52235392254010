import {useState,useEffect, useCallback,useMemo, useRef} from 'react'
import {createEditor, Editor, Element as SlateElement} from 'slate'
import {withReact, Slate, Editable} from 'slate-react'
import {renderLeaf} from '../EditorHelpers/leafRender'
import {renderElement} from '../EditorHelpers/elementRender'
import {withFlashcards} from "../EditorHelpers/flashcard"

export default function MiniDisplay(props){
    const [value, setValue] = useState(props.displayValue)
    const eTagList = props.displayValue[0].children[0].children
    const [tagList, setTagList] = useState(eTagList)
    const externalChange = useRef(false)
    const {forScreen} = props
    const elementRender = useCallback(props => {
        props.forScreen = forScreen
        return renderElement(props)
    }, [])
    const leafRender = useCallback(props => renderLeaf(props), [])

    const editor = useMemo(() => withFlashcards(withReact(createEditor())), [])
 
    useEffect(()=>{
        externalChange.current = true
        setValue(props.displayValue)
        setTagList(props.displayValue[0].children[0].children)
    }, [props.displayValue])

    useEffect(()=>{
        if ("changed" in props){
            if (externalChange.current){
                externalChange.current = false
            }
            else{
                const newTagList = value[0].children[0].children
                console.log(tagList)
                console.log(newTagList)
                if (tagList.length !== newTagList.length){
                    console.log("changing")
                    props.changed(value)
                }    
            }
        }
    }, [value])

    return (
        <div style={props.style}>
            <Slate editor={editor} value={value} onChange={newVal=>setValue(newVal)}>
                <Editable
                renderElement={elementRender}
                renderLeaf={leafRender}
                />
            </Slate>
        </div>
    )
  }
  