import React, {useState} from 'react'
import axios from 'axios'
import {useAuth} from '../../hooks/use-auth'
import {MoreVert as MoreVertIcon, Folder as FolderIcon, SignalCellularNullSharp} from '@material-ui/icons';
// import FolderIcon from '@material-ui/icons/Folder';
import {Dropdown} from 'react-bootstrap'

export default function CollectionRow(props){
    const {setCollections} = props
    const {col} = props
    const [renameValue, setRenameValue] = useState(null)
    const {docsSelected, setDocsSelected} = props
    const auth = useAuth()
    const selectCollection = (e) => {
        e.preventDefault()
        setDocsSelected(prev=>{
            const newDocsSelected = new Map(prev)  
            col.documents.forEach(docID=>{
                newDocsSelected.set(docID, !prev.get(docID))
            })
            return newDocsSelected
        })
    }
    const addToCollection = (e) => {
        e.stopPropagation()
        const selected = Array.from(docsSelected.entries()).filter(([k,v])=>v).map(([k,v])=>k)
        const selectedMap = new Map()
        selected.forEach(docID=>{
            selectedMap.set(docID, true)
        })
        setCollections(prev=>{
            const newCollections = []
            console.log("old", prev)
            prev.forEach(prevCollection=>{
                if (prevCollection.id === col.id){
                    const newCollection = {...col, documents: [...col.documents, ...selected]}
                    newCollections.push(newCollection)
                    return
                }
                const newDocuments = prevCollection.documents.filter(docID=>!selectedMap.has(docID))
                const newCollection = {...prevCollection, documents: newDocuments}
                newCollections.push(newCollection)
            })
            console.log("new", newCollections)
            axios.put(`/collections`, newCollections, {headers:{Authorization:auth.user.token}})
            return newCollections
        })
        // setDocsSelected(prev=>{
        //     const newDocsSelected = new Map(prev)  
        //     Array.from(newDocsSelected.keys()).map(k => {
        //         newDocsSelected.set(k, false)
        //     })
        //     return newDocsSelected
        // })
    }
    const deleteCollection = (e) => {
        axios.delete("/collections", [col.id],{headers:{Authorization:auth.user.token}})
        setCollections(prev=>{
            const newCollections = prev.filter(oldcollection=>oldcollection.id !== col.id)
            return newCollections
        })
    }
    const submitRename = (e) => {
        if (e.keyCode == 13){
            axios.put("/collections", [{...col, collection: renameValue}], {headers:{Authorization:auth.user.token}})
            setCollections(prev=>{
                const newCollections = prev.map(collection => {
                    if (collection.id == col.id){
                        return {...collection, collection: renameValue}
                    }
                    return collection
                })
                return newCollections
            })
            setRenameValue(null)
        }
        if (e.keyCode == 27){
            setRenameValue(null)
        }
    }
    const changeToRename = (e) =>{
        setRenameValue(col.collection)
        e.stopPropagation()
    }

    const openCollection = () => {

    }

    const [hovered, setHovered] = useState(false)
    let rowStyle={
        height: "50px"
    }
    if (hovered){
        rowStyle.backgroundColor = "red"
    }
    function onDragEnter(e){
        e.preventDefault()
        setHovered(true)
    }
    function onDragLeave(e){
        e.preventDefault()
        setHovered(false)
    }
    function onDrop(e){
        addToCollection(e)
        setHovered(false)
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation()
            onClick(e);
        }}
        >
        {children}
        <MoreVertIcon/>
        </a>
    ));

    const style = {
        margin: 0
    }

    const titleInput = <input onClick={e=>e.stopPropagation()} type="text" value={renameValue} onChange={e=>setRenameValue(e.target.value)} onKeyDown={submitRename}/>
    const titleShow = <div style={{border: "1px solid white", display:"inline"}} onClick={changeToRename}>{col.collection}</div>
    return (
        <div onClick={selectCollection} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={e=>e.preventDefault()} onDrop={onDrop} key={col.id} class="py-2 row align-items-center justify-content-between">
            <div style={style} class="col-auto d-flex align-items-center"><FolderIcon style={{color:"lightblue"}}/>{renameValue === null?titleShow:titleInput}</div>
            <div class="col-auto">
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={openCollection}>Open</Dropdown.Item>
                        <Dropdown.Item onClick={changeToRename}>Rename</Dropdown.Item>
                        <Dropdown.Item onClick={addToCollection}>Add Selected</Dropdown.Item>
                        <Dropdown.Item onClick={deleteCollection}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}