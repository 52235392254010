import { Editor, Element as SlateElement, Point, Transforms, Range, Node, Path } from 'slate'
import { Close } from '@material-ui/icons';
import { ReactEditor, useEditor } from 'slate-react'

// Everything flashcard related should be in this file, including the formatting, rendering, plugin, commands, etc.

// So this formatting is necessary to establish the format tree, but afterwards the
// HTML inflation will happen in the rendering type switch.

export function createFC(){
    return {
        type: "fcContainer",
        lastReview: Date.now(),
        level: 1,
        children: [
            {
                type: "fcTagList",
                children: [
                    {
                        type: "nextTag",
                        children:[{text:""}]
                    }
                ]
            },
            {
                type: "fcFront",
                children: [
                    {
                        type:"paragraph",
                        children: [{text: ""}]
                    }
                ],
            },
            {
                type: "fcBack",
                children: [
                    {
                        type:"paragraph",
                        children: [{text: ""}]
                    }
                ],
            }
        ]
    }
}

function Tag(props) {
    const editor = useEditor()
    const style = {
        border:"solid black 0.1px", 
        backgroundColor: "#E3FFFC",
        borderRadius: "5px",
        padding: "2px",
        height:"auto"
    }
    return (
        <div {...props.attributes} style={style} contentEditable={false} 
        className="m-1 fcTag d-flex align-items-center justify-content-center">
            {props.children}
            <Close onClick={(e)=>{
                const loc = ReactEditor.findPath(editor, props.element)
                e.preventDefault()
                Transforms.removeNodes(editor, {at:loc})}}/>
        </div>
    )
}

function RemoveFlashcardButton(props){
    const editor = useEditor()
    return (
        <button {...props.attributes} contentEditable={false} className="btn btn-light" 
        onClick={(e)=>{
            e.preventDefault()
            const loc = ReactEditor.findPath(editor, props.element)
            const [,fcLoc] = Editor.above(editor, {at: loc, match:n=>n.type==="fcContainer"})
            Transforms.removeNodes(editor, {at:fcLoc})
        }}>
            <Close/>
        </button>
    )
}

export function FullFlashcard(props) {
    switch(props.element.type){
        case "fcContainer":
            const style={
                maxWidth: "90%",
                borderRadius: "16px",
                border: "solid 1px lightgray" 
            }

            return (
                // TODO fully wrapping container with onclick bottom to allow for line insertion
                <div {...props.attributes} style={style}
                className="fcContainer mx-auto">
                    {props.children}
                </div>
            )
        case "fcTagList":{
            const style = {
                backgroundColor: "#F7F7F7",
                borderBottom: "solid gray 1px",
                borderTopRightRadius:"16px",
                borderTopLeftRadius:"16px",
                position: "relative",
                width: "100%",
                height: "auto",
                padding: "5px"
            }
            let RemoveButton = <RemoveFlashcardButton/>
            if ("forScreen" in props && props.forScreen === "fcviewer"){
                RemoveButton = ""
            }
            let otherAttr = {}
            if ("forScreen" in props && props.forScreen === "fcviewer"){
                otherAttr = {"onClick": e=>e.stopPropagation()}
            }
            return (
                <div className="container-fluid" {...otherAttr} style={style}>
                    <div className="row justify-content-between">
                        <div {...props.attributes} className="d-flex flex-wrap align-items-center col">
                            {props.children}
                        </div>
                        <div contentEditable={false} className="col-auto">
                            {RemoveButton}
                        </div>
                    </div>
                </div>
            )}
        case "fcTag":{
            return <Tag {...props} />
        }
        // Must do something with the placeholders for the below two.
        case "fcFront":{
            const style = {
                minHeight: "100px",
                borderBottom: "solid lightgray 0.5px",
                position: "relative",
                margin: "5px"
            }

            let className = props.element.type
            if (props.element.children.length === 1 && props.element.children[0].type === "paragraph" && props.element.children[0].children[0].text === ""){
                className += " frontNoValue"
            }
            if ("forScreen" in props && props.forScreen === "fcviewer"){
                return (
                    <div {...props.attributes} onMouseDown={e=>e.preventDefault()}
                    className={className} style={style}>
                        <div>
                            {props.children}
                        </div>
                    </div>
                )
            }
            return (
                <div {...props.attributes}
                className={className} style={style}>
                    <div>
                        {props.children}
                    </div>
                </div>
            )       
        }     
        case "fcBack":{
            const style = {
                minHeight: "100px",
                position: "relative",
                margin: "5px"
            }

            let className = props.element.type
            if (props.element.children.length === 1 && props.element.children[0].type === "paragraph" && props.element.children[0].children[0].text === ""){
                className += " backNoValue"
            }
            if ("forScreen" in props && props.forScreen === "fcviewer"){
                return (
                    <div {...props.attributes} onMouseDown={e=>e.preventDefault()}
                    className={className} style={style}>
                        <div>
                            {props.children}
                        </div>
                    </div>
                )
            }
            return (
                <div {...props.attributes} 
                className={className} style={style}>
                    <div>
                        {props.children}
                    </div>
                </div>
            )
        }
    }
}


export function flashcardRender(props){
    // Collapsed or not
    if (props.element.collapsed){
        // If collapsed, we just show a line of the front and back, like with regular Anki.
    }
    else{
        return <FullFlashcard {...props} />
    }
}

export function isFlashcard(node){
    const fcTypes = ["fcContainer", "fcTagList", "fcTag", "fcFront", "fcBack"]
    return fcTypes.includes(node.type)
}

// Make sure that the user can't delete elements within the flashcard. The flashcard is only meant to be deleted using a button.
// List of properties:
// All flashcard elements are immune from deletion. The only way to delete a flashcard is to click the button.
// Deletion is not allowed within the taglist. The only way to remove a tag is to click the button.
export const withFlashcards = editor => {
    //     const { deleteBackward, deleteForward, insertBreak } = editor
    const { deleteBackward, deleteForward, deleteFragment, insertBreak, normalizeNode } = editor

    const fcComponents = ["fcContainer", "fcTagList", "fcTag", "nextTag", "fcFront", "fcBack"]

    editor.deleteBackward = (...args) => {
        const { selection } = editor
        console.log("dbsel", selection)
    
        // TODO block deletes on the taglist. The only way to remove tags should be to click on them.
        if (selection && Range.isCollapsed(selection)) {
            // Check if deleting would move the cursor into a fc component. If so, reject delete.
            const curCel = Editor.above(editor, {at: selection.anchor, match: n=>fcComponents.includes(n.type)})
            const prevCell = Editor.before(editor, selection.anchor)
            if (!curCel && prevCell){
                const prevCellFC = Editor.above(editor, {at:prevCell.path, match:n=>fcComponents.includes(n.type)})
                if (prevCellFC){
                    return
                }
            }

            const cell = Editor.above(editor, {match: node=>fcComponents.includes(node.type)})
            console.log(cell)
            if (cell) {
                // If at the start of a container, return
                const [, cellPath] = cell
                const start = Editor.start(editor, cellPath)

                if (Point.equals(selection.anchor, start)) {
                    return
                }
            }
            else{
                const match = Editor.above(editor, {
                    match: n => Editor.isBlock(editor, n),
                })
            
                if (match) {
                    const [block, path] = match
                    const start = Editor.start(editor, path)
            
                    if (
                    !Editor.isEditor(block) &&
                    SlateElement.isElement(block) &&
                    block.type !== 'paragraph' &&
                    Point.equals(selection.anchor, start)
                    ) {
                    const newProperties = {
                        type: 'paragraph',
                    }
                    Transforms.setNodes(editor, newProperties)
            
                    if (block.type === 'list-item') {
                        Transforms.unwrapNodes(editor, {
                        match: n =>
                            !Editor.isEditor(n) &&
                            SlateElement.isElement(n) &&
                            n.type === 'bulleted-list',
                        split: true,
                        })
                    }
            
                    return
                    }
                }
            
                deleteBackward(...args)
                return
            }
        }
    
        deleteBackward(...args)
        // TODO Selection does not contain any flashcard components.
      }
    
    editor.deleteForward = (...args) =>{
        const {selection} = editor

        console.log("dfor", selection)
        deleteForward(...args)
    }

    editor.deleteFragment = (...args) => {
        const {selection} = editor
        console.log("dfsel",selection)

        if (selection && Range.isExpanded(selection)){
            const commonPath = Path.common(selection.anchor.path, selection.focus.path)
            console.log("common", commonPath)
            const commonFC = Editor.above(editor, {at: commonPath, match: node=>node.type==="fcContainer"}) || Node.get(editor, commonPath).type==="fcContainer"? Node.get(editor, commonPath):undefined
            const anchorFC = Editor.above(editor, {at: selection.anchor.path, match: node=>fcComponents.includes(node.type)})
            const focusFC = Editor.above(editor, {at: selection.focus.path, match: node=>fcComponents.includes(node.type)})
            // console.log("commonfc", commonFC)
            // console.log("anchorfc", anchorFC)
            // console.log("focusfc", focusFC)
            if (commonFC && anchorFC && focusFC && !Path.equals(anchorFC[1], focusFC[1])){
                return
            }
            if ((anchorFC && !focusFC) || (focusFC && !anchorFC)){
                return
            }
        }
        deleteFragment(...args)
    }

    editor.insertBreak = (...args) => {
        const { selection } = editor

        if (selection) {
            // If the break has been made inside a taglist element, then the current leaf node should be turned into a tag element
            // and the selection should be moved to the end.
            // I shouldn't be doing this in here, I should be doing this as an event grab.
            const table = Editor.above(editor, {
                match: n =>
                SlateElement.isElement(n) &&
                n.type === 'nextTag',
            })
            console.log(table)

            if (table) {
                // const [, taglistPath] = table
                // Transforms.setNodes(editor, {
                //     type: "fcTag",
                //     flashcard: true,
                // }, {at: taglistPath})
                // const newTag = {
                //     type: "nextTag",
                //     children:[{text:""}]
                // }
                // console.log(taglistPath)
                // taglistPath[taglistPath.length-1]+=1
                // console.log(taglistPath)
                // Transforms.insertNodes(editor, newTag, {at:taglistPath})
                // const [,newLoc] = Editor.next(editor, {at: taglistPath, match: n=>{return "text" in n}})
                // Transforms.select(editor, newLoc)
                const [oldNextTag, taglistPath] = table
                console.log(oldNextTag)
                Transforms.insertNodes(editor, {
                    type: "fcTag",
                    flashcard: true,
                    children: oldNextTag.children
                }, {at: taglistPath})
                Transforms.delete(editor, {
                    unit: "line",
                    reverse: true
                })
                // const newTag = {
                //     type: "nextTag",
                //     children:[{text:""}]
                // }
                // console.log(taglistPath)
                // taglistPath[taglistPath.length-1]+=1
                // console.log(taglistPath)
                // Transforms.insertNodes(editor, newTag, {at:taglistPath})
                // const [,newLoc] = Editor.next(editor, {at: taglistPath, match: n=>{return "text" in n}})
                // Transforms.select(editor, newLoc)
                return
            }
        }

        insertBreak(...args)
    }

    // editor.normalizeNode = entry => {
    //     const [node, path] = entry
    
    //     // If the element is a fcContainer, make sure that the next sibling is a empty line.
    //     console.log("lasdkjfadkf", node)
    //     if (SlateElement.isElement(node) && node.type === 'fcContainer') {
    //         const test = Editor.next(editor, {at:path})
    //         console.log("insidenorm", test)
    //     }
    
    //     // Fall back to the original `normalizeNode` to enforce other constraints.
    //     normalizeNode(entry)
    //   }
    

    return editor
    
}