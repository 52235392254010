import React from 'react'
import {useAuth} from '../../hooks/use-auth'
import {useHistory} from 'react-router-dom'

export default function LogoutButton() {
    const auth = useAuth()
    const history = useHistory()

    const logOutUser = (e) => {
        e.preventDefault()
        auth.signout()
        .then(history.push("/login"))
    }

    return (
        <button onClick={logOutUser} className="btn btn btn-outline-dark">Log Out</button>
    )
}
