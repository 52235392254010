// Import React dependencies.
import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import Navbar from '../components/GeneralNavbar'
import Sidebar from '../components/Sidebar'
import './FCPlatform.css';
import FCEditor from '../components/FCEditor'
import {useSelectedDocs} from '../hooks/use-selectedDocs'

export default function FCPlatform(){
  const [selectedDocs, sdController] = useSelectedDocs()
  const [saveEverything, setSaveEverything] = useState(false)
  const [editorIndex, setEditorIndex] = useState(0)
  const [showOnlyFlashcards, setShowOnlyFlashcards] = useState(false);

  const metaMap = useRef(new Map())

  function SingleEditor(props){
    const {doc} = props
    const updateDoc = (info) => {
      metaMap.current.set(doc.id, info)
    }
    const clickDoc = (e) => {
      // sdController.setDocsFromMap(metaMap.current)
      // if (doc.id !== editorIndex){
      //   setEditorIndex(doc.id)
      // }
    }
    return (
      <div className="row py-5 justify-content-center" key={doc.id} onClick={clickDoc}>
        <FCEditor showSidebar={editorIndex === doc.id} initDoc={doc} updateDoc={updateDoc}
        saveEverything={saveEverything} showOnlyFlashcards={showOnlyFlashcards}/>
      </div>
    )
  }

  const [lastSaved, setLastSaved] = useState(Date.now())

  const buildDocsMeta = () =>{
    const docsMeta = []
    selectedDocs.forEach(doc=>{
      const {title, contents} = metaMap.current.get(doc.id)

      const tagList = new Map()
      contents.forEach(node=>{
        if (node.type === 'fcContainer'){
          node.children[0].children.forEach(tag=>{
            if (tag.type === "fcTag"){
              tagList.set(tag.children[0].text, true)
            }
          })
        }
      })
      console.log("got tags", Array.from(tagList.keys()))
      const newDoc = {
          ...doc,
          title, contents,
          tagList: Array.from(tagList.keys())
      }
      console.log("built new doc", newDoc)
      docsMeta.push(newDoc)
    })
    return docsMeta
  }
  async function saveDoc(){
    const newDocs = buildDocsMeta()
    return sdController.saveDocs(newDocs).then(got=>{
      console.log("got this", got)
      return got
    })
  }

  return (
    <>
      <Navbar saveWorking={saveDoc}>
        <div onClick={saveDoc}>
          Last Saved {lastSaved}
        </div>
      </Navbar>
      <div className="container-fluid" style={{backgroundColor:"#f2f2f2", minHeight:"1000px"}}>
          {selectedDocs.map(doc=><SingleEditor doc={doc}/>)}
      </div>
    </>
  )
}